#catatan-container {
    padding-top: 4rem !important;
}

#catatan-container .catatan-title {
    font-weight: 600 !important;
}

#catatan-container .card-img-container {
    height: 10rem !important;
}

#catatan-container .card-img-container svg {
    font-size: 3rem !important;
}

#catatan-container .card-img-container .catatan-thumb-dropdown button {
    color: black !important;
}

#catatan-container .card-img-container .dropdown-toggle::after {
    display: none !important;
}

#catatan-container .catatan-datepicker .dropdown-toggle::after {
    display: none !important;
}

#catatan-container .catatan-datepicker .dropdown button {
    background-color: white !important;
    border: 2px !important;
    border-radius: 5px !important;
    width: 16rem !important;
}

#catatan-container .catatan-grid .card {
    height: 18.5rem !important;
}

#catatan-container .catatan-grid .card .card-title,
#catatan-container .catatan-grid .card .card-subtitle {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
}

#catatan-container .catatan-grid .card .card-subtitle {
    margin-bottom: 0.5rem !important;
}

#catatan-container .catatan-grid .card .card-title {
    margin-top: 0.5rem !important;
}

#catatan-container #catatan-date-grid-container .catatan-date {
    font-weight: 400 !important;
}

#catatan-container #catatan-date-grid-container .card .dropdown-menu .fa-trash-can {
    color: red !important;
    height: 1rem !important;
    margin-top: auto !important;
    margin-bottom: auto !important;
    margin-right: 0.25rem !important;
    vertical-align: middle !important;
}

#catatan-popup-modal #rdw-wrapper-5484.catatan-wrapper {
    border-bottom: 0 !important;
}
/* Gaya untuk .learning-material-container */
.learning-material-container {
  /* Gaya-gaya yang Anda inginkan hanya untuk elemen dalam komponen ini */
}

/* Gaya untuk .section-materials */
.learning-material-container .section-materials {
  background: #f8f8ff;
}

/* Gaya untuk .card */
.learning-material-container .card {
  border-radius: 12px;
}

/* Gaya untuk .card-header */
.learning-material-container .card .card-header {
  padding: 0;
}

/* Gaya untuk .card-header img */
.learning-material-container .card .card-header img {
  border-radius: 12px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  width: 100%;
}

/* Gaya untuk .card-body */
.learning-material-container .card .card-body {
  padding: 12px 12px 24px;
}

/* Gaya untuk .card-title */
.learning-material-container .card .card-title {
  line-height: 31px;
  font-weight: 600;
  font-size: 23px;
  margin-bottom: 4px;
}

/* Gaya untuk .card-content */
.learning-material-container .card .card-content {
  line-height: 24px;
  font-size: 14px;
  color: #525a80;
  margin-bottom: 24px;
}

/* Gaya untuk .card-button */
.learning-material-container .card .card-button {
  display: flex;
  border-radius: 8px;
  background-color: #f8f8ff;
  padding: 4px 12px;
  align-items: center;
  justify-content: center;
  gap: 8px;  
  color: #2854a3;
  font-weight: 600;
}

@media (max-width: 992px) {
  /* Gaya untuk .card-button pada layar berukuran maksimum 992px */
  .learning-material-container .card .card-button {
    font-size: 14px;
  }

  .learning-material-container .card .card-button img {
    width: 16px;
    height: 16px;
  }
}

.section-registration {
  background: #f8f8ff;
  padding: 24px 60px;
  border-radius: 24px;
}

.img-mentor {
  height: 319px;
  width: 100%;
  object-fit: contain;
}

@media (max-width: 992px) {
  .section-registration {
    padding: 24px 16px;
  }
}
.btn-hover {
  color: white !important;
  background-color: #033580 !important;
}
.nav-pills .nav-link {
    color: #000000 !important;
    border-radius: 0 !important;
}

.nav-pills .nav-link .nav-icon {
    color: #263238 !important;
}

.nav-pills .nav-link.active {
    color: #0078D7 !important;
    background-color: #F0F0F0 !important;
    border-right: 5px solid #0078D7 !important;
}

.nav-pills .nav-link:hover {
    background-color: #EEEEEE !important;
}

.accordion-item-nav {
    border: none !important;
}

.accordion-header-nav button {
    padding: 0.5rem !important;
    padding-left: 1.5rem !important;
    line-height: 1.5 !important;
}

.accordion-header-nav .accordion-button:not(.collapsed) {
    color: #263238 !important;
    background-color: #FFFFFF !important;
    box-shadow: none !important;
}

.accordion-header-nav .accordion-button:not(.collapsed) > .accordion-item-header {
    color: #0078D7 !important;
}

.accordion-header-nav .accordion-button:hover {
    background-color: #EEEEEE !important;
}

.accordion-header-nav .accordion-button:focus {
    border: none !important;
    box-shadow: none !important;
}

.accordion-body-nav .nav-link {
    padding-left: 5rem !important;
}

.nav-expanded .navbar.position-fixed {
    position: fixed !important;
    height: 100%;
    overflow-y: auto;
  }
  
  .nav-expanded .navbar.position-fixed .navbar-collapse {
    background-color: white;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 1000;
  }
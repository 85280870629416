.breadcrumb {
    margin-bottom: 0 !important;
}

.breadcrumb-item>a {
    color: grey !important;
    text-decoration: none;
}

.breadcrumb-item.active {
    color: #263238 !important;
}

.shadow-custom {
    box-shadow: 0 .25rem 0.5rem rgba(0, 0, 0, .5) !important;
}
#download-container > div.bg-white.container {
    background-color: transparent !important;
    margin-top: 1rem !important;
    padding: 0 !important;
}

#download-container > div.bg-white.container > div.row {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
}

#download-container > div.bg-white.container > div.row:nth-child(3) {
    background-color: white !important;
    border-radius: .5rem !important;
}

#download-container > div.bg-white.container .table > :not(:first-child),
#download-container > div.bg-white.container .table thead .border-top {
    border-top: 0 !important;
}

#download-container > div.bg-white.container .table thead {
    border-bottom: 3px #979797 !important;
}

#download-container div.bg-white.rounded.container div.row div.col-7 {
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
}

#download-container div.bg-white.rounded.container div.row div.col-7 {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

#download-container > div.bg-white.container div.my-2.row div.col-5.ps-0 {
    display: none !important;
}

#download-popup-detil .subbab-item .right-arrow-26px {
    height: 26px;
}

#download-popup-detil .subbab-item {
    font-size: 1rem !important;
}

#download-popup-detil {
    width: 30%;
    max-width: 30%;
}

#download-container > div.container > .row > h4 {
    font-weight: 700 !important;
}

#download-container > div.bg-white.container .table th:nth-child(6),
#download-container > div.bg-white.container .table th:nth-child(7) {
    text-align: center !important;
}

#download-container > div.bg-white.container .table tbody td:nth-child(6),
#download-container > div.bg-white.container .table tbody td:nth-child(7) {
    text-align: center !important;
}

#download-container > div.bg-white.container .table tbody td:nth-child(6) button,
#download-container > div.bg-white.container .table tbody td:nth-child(7) button {
    width: 100% !important;
}
@import url(//fonts.googleapis.com/css?family=Inter);

body {
    font-family: Inter !important;
    background-color: #E5E5E5 !important;
}

.fsz-075 {
    font-size: 0.75rem !important;
}

.fsz-0875 {
    font-size: 0.875rem !important;
}

.fsz-1125 {
    font-size: 1.125rem !important;
}

.fsz-125 {
    font-size: 1.25rem !important;
}

.fsz-225 {
    font-size: 2.25rem !important;
}

.fsz-15 {
    font-size: 1.5rem !important;
}

.fsz-3 {
    font-size: 3rem !important;
}

.w-30 {
    width: 30% !important;
}

.min-w-30 {
    min-width: 30% !important;
}

.asterisk {
    color: #E84234 !important;
}

.tag-tooltip > .tooltip-arrow::before {
    border-top-color: #FFFFFF !important;
}

.tag-tooltip > .tooltip-inner {
    color: #0078D7 !important;
    background-color: #FFFFFF !important;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
    font-size: 1rem !important;
}

.tag-tooltip-button:focus {
    outline: none !important;
    box-shadow: none !important;
}

.forgot-password-link {
    color: #0E4DA4 !important;
}

.login-footer-link {
    color: #FFBB12 !important;
}

.login-form-element {
    border: 1px solid #CED4DA !important;
}

.login-form-element-error {
    border: 2px solid #EA4335 !important;
}

#header-talent.navbar {
    box-shadow: 0px 4px 9px 4px rgba(0, 0, 0, 0.25);
}

#header-talent #bell-icon .dropdown-menu {
    top: 70px !important;
    right: 0px !important;
    box-shadow: none !important;
}

#header-talent #user-icon {
    margin-left: 4.75rem !important;
}

#header-talent #user-icon .dropdown-menu {
    font-size: 1.5rem !important;
    top: 70px !important;
    left: -110px !important;
    box-shadow: none !important;
}

#header-talent #user-icon .dropdown-menu .dropdown-divider {
    border: 1px solid #979797;
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
}

#header-talent #user-icon .dropdown-menu .dropdown-item {
    color: grey !important;
}

#header-talent .dropdown-toggle.header-icon::after {
    display: none !important;
}

#header-talent .pe-6 {
    padding-right: 6rem !important;
}

#header-talent img.logo79 {
    height: 3rem !important;
    margin-top: 1.75rem !important;
    margin-bottom: 1.75rem !important;
    margin-left: 7rem !important;
}

#header-talent svg.header-icon {
    color: rgba(0, 0, 0, 0.54) !important;
    height: 2.4375rem !important;
}

.edit-profile .mb-6 {
    margin-bottom: 5rem !important;
}

.profil-modal-header{
    border-bottom: none !important;
    padding-top:2rem !important;
    padding-left:2rem !important;
}

.profil-modal-header img.logo79{
    height: 2.5rem !important;
}

.profil-modal-header .btn-close{
    margin-bottom: 1rem !important;
    margin-right: 1rem !important;
}
.profil-modal-body .image-container{
    height: 8rem;
    width: 8rem;
    border-radius: 50%;
}
.image-container > img{
    width: 100%; /* or any custom size */
    height: 100%; 
    object-fit: contain;
}

.profil-modal-body .talent-name{
    font-weight: bold;
    margin: 0 auto;
}

.profil-modal-body  .xp-row{
    font-size: 0.75rem !important;
}

.profil-modal-body .button-foot{
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2), 0px 4px 8px rgba(0, 0, 0, 0.2);
}
.password-modal-body h4{
    font-weight: bold;
}

.responsive-notification {
    align-items: end;
}

.topik-thumbnail-image{
    width: 4.6rem;
}
.tingkatan-mudah{
    background-color: #34A853 !important;
    padding-right: .6em;
    padding-left: .6em;
    border-radius: 10rem !important;
}
.tingkatan-sedang{
    background-color: #FBBC04 !important;
    padding-right: .6em;
    padding-left: .6em;
    border-radius: 10rem !important;
}
.tingkatan-sulit{
    background-color: #EA4335 !important;
    padding-right: .6em;
    padding-left: .6em;
    border-radius: 10rem !important;
}
.card-mentor {
  border: 1px solid #e7e8ec;
  padding: 0 0 12px;
}

.card-mentor-header {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}

.card-mentor-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.card-mentor-exp {
  font-size: 16px;
  color: #525a80;
}

.img-person {
  position: relative;
  top: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
  transform: translateY(7%);
}
.tingkatan {
    width: 100%;
    padding: 5px;
    border-radius: 20px;
    background-color: darkred;
    color: white;
    display: inline-block;
    text-align: center;
    font-size: 0.6rem;
}

.search-input {
    border-right: none !important;
}

.search-icon {
    border-left: none !important;
}

.select-column {
    width: 100%;
    min-height: 200px;
    position: absolute;
    background-color: white;
    top: 3em;
    right: 0;
    border-radius: 5%;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
    /* display: none; */
}

.has-search .form-control {
    padding-left: 2.375rem;
}

.has-search .form-control-feedback {
    position: absolute;
    z-index: 2;
    display: block;
    width: 2.375rem;
    height: 2.375rem;
    line-height: 2.375rem;
    text-align: center;
    pointer-events: none;
    color: #aaa;
}

::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 10px lightgrey;
    border-radius: 10px;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #8B9CED;
    border-radius: 10px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #8B9CED;
  }
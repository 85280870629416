#screenshot-container {
    padding-top: 4rem !important;
}

#screenshot-container .screenshot-title {
    font-weight: 600 !important;
}

#screenshot-container .card-img-container {
    height: 10rem !important;
}

#screenshot-container .card-img-container svg {
    font-size: 3rem !important;
}

#screenshot-container .card-img-container .screenshot-thumb-dropdown button {
    color: black !important;
}

#screenshot-container .card-img-container .dropdown-toggle::after {
    display: none !important;
}

#screenshot-container .screenshot-datepicker .dropdown-toggle::after {
    display: none !important;
}

#screenshot-container .screenshot-datepicker .dropdown button {
    background-color: white !important;
    border: 2px !important;
    border-radius: 5px !important;
    width: 16rem !important;
}

#screenshot-container .screenshot-grid .card {
    height: 18.5rem !important;
}

#screenshot-container .screenshot-grid .card .card-title,
#screenshot-container .screenshot-grid .card .card-subtitle {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
}

#screenshot-container .screenshot-grid .card .card-subtitle {
    margin-bottom: 0.5rem !important;
}

#screenshot-container .screenshot-grid .card .card-title {
    margin-top: 0.5rem !important;
}

#screenshot-container #screenshot-date-grid-container .screenshot-date {
    font-weight: 400 !important;
}

#screenshot-container #screenshot-date-grid-container .card .dropdown-menu .fa-trash-can {
    color: red !important;
    height: 1rem !important;
    margin-top: auto !important;
    margin-bottom: auto !important;
    margin-right: 0.25rem !important;
    vertical-align: middle !important;
}

#screenshot-popup-modal #rdw-wrapper-5484.catatan-wrapper {
    border-bottom: 0 !important;
}

.title{
    font-size: 25px;
    font-weight: 600;
}

.modal-body-screenshot{
    overflow: hidden !important;
    padding: 0px !important;
}

.modal-body-screenshot img{
    width: 100%;
    border-radius: 4px;
}

.modal-body-screenshot .close-button{
    position: absolute;
    right: 4px;
    top: 4px;
    background-color: rgba(255, 255, 255, 0.5);
}

.txt-popup-title{
    font-size: 12px;
    font-weight: 500;
}

.txt-popup-content{
    font-size: 18px;
    font-weight: 600;
}
.kuis-back-button {
    background-color: #FFFFFF !important;
}

.kuis-back-button:hover {
    background-color: #0d6efd !important;
}

.kuis-button {
    width: 9rem !important;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2), 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.kuis-container {
    width: 83.75% !important;
}

.nav-item-soal-kuis > .nav-link {
    cursor: pointer !important;
    border-radius: 50% !important;
    border: 1px solid #0078D7 !important;
    color: #0078D7 !important;
    font-weight: 700 !important;
    font-size: 1.125rem !important;
    padding: 0 !important;
    width: 2rem !important;
    height: 2rem !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}

.nav-item-soal-kuis > .nav-link.disabled {
    opacity: 0.5 !important;
}

.nav-item-soal-kuis > .nav-link.active {
    border: 1px solid #0078D7 !important;
    background-color: #0078D7 !important;
    color: #FFFFFF !important;
}

#container-laporan-belajar .info-talent-col button.btn-primary {
    color: black !important;
    background-color: transparent !important;
    border-color: transparent !important;
}

#container-laporan-belajar .info-talent-col>div {
    width: 15rem !important;
}

#container-laporan-belajar .info-talent-col .profile-name {
    overflow: hidden !important;
    max-width: 8rem !important;
}

#container-laporan-belajar .button-list .button-list-item {
    width: calc(1/3*100% - (1 - 1/3)*2rem) !important;
}

#container-laporan-belajar .button-list .button-list-item .item-amountText {
    font-weight: 500 !important;
}

#container-laporan-belajar .button-list .button-list-item .item-typeText{
    font-weight: 400 !important;
    font-size: 0.75rem !important;
    color: grey !important;
}
div#bell-icon-dropdown.show.dropdown div.dropdown-menu.show a.dropdown-item div div.image-col.col {
    margin-right: 1rem;
}

div#bell-icon-dropdown.show.dropdown div.dropdown-menu.show a.dropdown-item div div.col {
    display: inline-block;
}

.bold-this {
    font-weight: bold;
}

.user-name,
.topic-name {
    color: #0078d7;
}

.shadow-custom {
    box-shadow: 0 .25rem 0.5rem rgba(0, 0, 0, .5) !important;
}

.notification-dropdown {
    max-height: 50vh !important;
    overflow-x: hidden !important;
    overflow-y: auto !important;
}

.text-ellipsis-y {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.unreaded-notif:hover {
    background-color: #e9ecef !important;
}

.unreaded-notif {
    background-color: #D2EBF7 !important;
}

.kuis-talent-materi-container {
    margin-top: 2.25rem !important;
    width: 83.75% !important;
}

.nav-item-soal-kuis-materi > .nav-link {
    cursor: pointer !important;
    border-radius: 50% !important;
    border: 1px solid #0078D7 !important;
    color: #0078D7 !important;
    font-weight: 700 !important;
    font-size: 1.125rem !important;
    padding: 0 !important;
    width: 2rem !important;
    height: 2rem !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}

.nav-item-soal-kuis-materi > .nav-link.disabled {
    opacity: 0.5 !important;
}

.nav-item-soal-kuis-materi > .nav-link.active {
    border: 1px solid #0078D7 !important;
    background-color: #0078D7 !important;
    color: #FFFFFF !important;
}

.opsi {
    background-color: #FFFFFF !important;
    border: 1px solid #263238 !important;
    border-radius: 8px !important;
    color: #263238 !important;
    opacity: 1 !important;
}

.opsi-correct-answer {
    border: 1px solid #34A853 !important;
    box-shadow: 0px 0px 4px 5px rgba(52, 168, 83, 0.25) !important;
}

.opsi-wrong-answer {
    border: 1px solid #EA4335 !important;
    box-shadow: 0px 0px 5px 5px rgba(234, 67, 53, 0.25) !important;
}


.landing-page * {
  font-family: 'Source Sans Pro', sans-serif;
}

/* Button */
.btn {
  font-weight: 600;
  padding: 12px 24px;
}

.btn-primary {
  line-height: 24px;
  font-weight: 600;
  border-radius: 8px;
  background-color: #2854a3;
  display: flex;
  flex-direction: row;
  padding: 8px 24px;
  align-items: center;
  justify-content: center;
}

.btn-light {
  color: #2854a3;
}

/* Accordion */
.accordion-item {
  border: 0;
  border-bottom: 1px solid #eaebf0;
}

.accordion-button {
  font-weight: 600;
  font-size: 14px;
  padding: 16px 0;
}

.accordion-button:not(.collapsed) {
  color: #212529;
  background: transparent;
  box-shadow: none;
}
.accordion-button:focus {
  box-shadow: none;
}
.accordion-button::after {
  width: 1rem;
  height: 1rem;
  background-size: 1rem;
}

.accordion-body {
  font-size: 14px;
  padding: 16px 0;
}

/* Utilities */
.space-container {
  padding: 80px 0px;
}

@media (max-width: 992px) {
  .space-container {
    padding: 80px 16px;
  }
}

.custom-btn:hover {
  color: #154394 !important;
}
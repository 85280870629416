.subbab-terakhir {
    background: linear-gradient(90deg, #0078D7 0%, rgba(0, 120, 215, 0.6) 100%);
    border-radius: 16px;
    color: white;
    padding: 2rem;
}

.accord-subbab-header {
    padding: 1.25rem !important;
    padding-bottom: 0 !important;
    background-color: transparent !important;
}

.accord-subbab-header .accordion-button {
    font-size: 1.5rem;
    font-weight: bold;
    padding:0 !important;
    padding-bottom: 1.25rem !important;
    border-top-left-radius: 16px !important;
    border-top-right-radius: 16px !important;
}

.accord-subbab-header .accordion-button.collapsed {
    border-bottom-left-radius: 16px !important;
    border-bottom-right-radius: 16px !important;
}

.accord-subbab-header .accordion-button:not(.collapsed) {
    background-color: white !important;
    color: #263238;
    border-bottom: 1px solid #263238 !important;
}

.accord-subbab-header > button:not(.collapsed)::after {
	background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23000000%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e") !important;
}
.subbab-timeline{
    padding:1.25rem;
    border-radius: 1em;
}

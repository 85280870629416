.popuptambaheditakun .input-group-text {
    background-color: transparent !important;
    border-left-style: none !important;
}

.popuptambaheditakun .input-group-text svg {
    color: gray !important;
    margin-right: 0 !important;
}

.popuptambaheditakun .modal-dialog {
    width: 62.5rem !important;
    max-width: 62.5rem !important;
}
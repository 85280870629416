.kuism-thumbnail-cell {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
}

.kuism-thumbnail-image {
    width: 4.6rem;
    height: 2.8rem;
    float: left;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden
}

.kuism-thumbnail-image img {
    flex-shrink: 0;
    min-width: 100%;
    max-width: 100%;
}

.kuism-link-style {
    color: black;
    text-decoration: none;
}

.custom-radio-answer {
    width: 100%;
    height: 100%;
}

.custom-radio-answer::before{
    content: '✔';
    margin-left: .3rem;
    display: inline-block;
    vertical-align: middle;
    line-height: normal;
}
.custom-radio-answer:checked::before{
    color: white;
}
.custom-radio-button{
    border-radius: 100% !important;
}

#navigation-talent {
    font-size: 1.125rem !important;
    padding-top: 4rem !important;
    padding-right: 2.4375rem !important;
    padding-left: 6.25rem !important;
}

#navigation-talent #navigation-profile {
    margin-bottom: 2.25rem !important;
    padding: 1.5rem !important;
}

#navigation-talent #navigation-profile img.profile-image {
    border-radius: 50% !important;
    width: 4rem !important;
    height: 4rem !important;
}

#navigation-talent #navigation-profile .profile-name {
    font-size: 1.375rem !important;
    vertical-align: middle !important;
}

#navigation-talent #navigation-profile .profile-name > .profile-name:focus {
    outline: none !important;
    box-shadow: none !important;
}
 
#navigation-talent #navigation-profile .profileItemNavItem {
    margin-bottom: 1.25rem !important;
}

#navigation-talent #navigation-profile .xp-row {
    font-size: 0.75rem !important;
}

#navigation-talent #navigation-normal .nav-link.active {
    background-color: #DADADA !important;
}

#navigation-talent .nav-item {
    width: 100% !important;
}

#navigation-talent .nav-link {
    margin-right: auto !important;
}

#navigation-talent .profile-name, #navigation-normal .nav-item span {
    color: #081F32 !important;
}

#navigation-talent .progress-bar {
    max-width: 100% !important;
}

.text-ellipsis {
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

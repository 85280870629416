.tp-background {
  background: #2854a3;
}

.tp-title {
  font-size: 46px;
  color: white;
  margin-bottom: 40px;
  font-weight: bold;
}

.tp-subtitle {
  font-size: 20px;
  color: white;
  margin-bottom: 8px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}

.tp-img {
  border-radius: 25px;
}

.tp-mb60 {
  margin-bottom: 60px;
}

.tp-mb40 {
  margin-bottom: 40px;
}

.card-class {
  cursor: pointer;
  padding: 24px;
  background: white;
  border-radius: 24px;
}

.card-class-title {
  font-size: 28px;
  font-weight: bold;
  line-height: 36px;
  margin-bottom: 8px;
}

.card-class-content {
  font-size: 14px;
  color: #525a80;
  line-height: 24px;
}
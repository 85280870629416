.bg-blue {
    background: linear-gradient(90deg, #0078d7 0%, rgba(0, 120, 215, 0.75) 100%);
}

.py-45 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
}

.pe-55 {
    padding-right: 3rem !important;
}

#bell-icon-dropdown>.dropdown-toggle::after {
    display: none !important;
}

div#user-icon-dropdown.show.dropdown div.dropdown-menu.show a.dropdown-item {
    font-weight: 800 !important;
    font-size: 1.5rem !important;
}

div#user-icon-dropdown.show.dropdown div.dropdown-menu.show {
    width: 100% !important;
}

.shadow-custom {
    box-shadow: 0 .25rem 0.5rem rgba(0, 0, 0, .5) !important;
}

.icon-color-white {
    color: white;
}

div#user-icon-dropdown button.btn {
    font-size: 1.25rem !important;
    font-weight: 700 !important;
}

div.dropdown button.dropdown-toggle.btn svg {
    height: 1.55rem !important;
}

button.dropdown-toggle.btn.btn-primary {
    background: none !important;
    border: none !important;
}

div#bell-icon-dropdown {
    margin-right: 2rem;
}

div#user-icon-dropdown button.dropdown-toggle.btn svg.fa-circle-user {
    margin-right: 1.5rem !important;
}

#user-icon-dropdown>.dropdown-toggle::after {
    margin-left: 7rem !important;
    padding-bottom: 0.25rem !important;
}

.btn.btn-primary:hover {
    color: #fff !important;
    background-color: #0d6efd !important;
    border-color: #0d6efd !important;
}
.dropdown-item.notification-row .col.image-col {
    margin-right: 1rem;
}

.dropdown-item.notification-row .col {
    display: inline-block;
}

.bold-this {
    font-weight: bold;
}

.user-name,
.topic-name {
    color: #0078d7;
}

.shadow-custom {
    box-shadow: 0 .25rem 0.5rem rgba(0, 0, 0, .5) !important;
}
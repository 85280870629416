.error-alert {
    margin-bottom: 2.25rem !important;
    margin-right: 2.5rem !important;
    border: 1px solid #EA4335 !important;
    width:25% !important;
    background-color: #FFF2F0 !important;
}

.success-alert {
    margin-bottom: 2.25rem !important;
    margin-right: 2.5rem !important;
    border: 1px solid #28A745 !important;
    width:25% !important;
    background-color: #E2F8EE !important;
}

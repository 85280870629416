.rangkuman-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-gap: 2rem;
}
.rangkuman-wrapper{
    padding-top: 4rem;
}

.rangkuman-thumb {
    height: 100%;
    width: 100%;
    max-width: 720px;
    object-fit: cover;
}

.rangkuman-thumb-name {
    margin: 0 auto;
}

.rangkuman-modal-header{
    border-bottom: none !important;
    padding-bottom: 0 !important;
}

.rangkuman-modal {
    z-index: -1;
    display: flex !important;
    justify-content: center;
    align-items: center;
}

.modal-open .rangkuman-modal{
    z-index: 1050;
}

.rangkuman-modal .modal-dialog {
    max-width: 100%;
    width: auto !important;
    display: inline-block;
}

.rangkuman-modal .modal-dialog {
    max-height: 90% !important;
    max-width: 90% !important;
}

.rangkuman-modal .modal-dialog img {
    max-height: 100% !important;
    max-width: 100% !important;
}
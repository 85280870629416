#pembahasan-soal .pembahasan-hr {
    padding: 0 12rem 0 12rem !important;
}

#pembahasan-soal .pembahasan-col {
    text-indent: 4rem !important;
}

#pembahasan-soal .jawaban-benar-col {
    color: green !important;
}

#pembahasan-soal .pembahasan-title {
    font-size: 1.125rem !important;
    font-weight: 500 !important;
}

#pembahasan-soal .jawaban-row {
    font-size: 1.125rem !important;
    font-weight: 500 !important;
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
}

#pembahasan-soal .referensi-col a {
    text-decoration: none !important;
}

.pembahasan-popup-header{
    border-bottom: none !important;
    padding-bottom: 0 !important;
}

.pembahasan-popup {
    z-index: -1;
    display: flex !important;
    justify-content: center;
    align-items: center;
}

.modal-open .pembahasan-popup{
    z-index: 1050;
}

.pembahasan-popup .modal-dialog {
    max-width: 100%;
    width: auto !important;
    display: inline-block;
}

.pembahasan-image {
    height: 100%;
    width: 100%;
    max-width: 720px;
    object-fit: cover;
}

.pembahasan-popup .modal-dialog {
    max-height: 90% !important;
    max-width: 90% !important;
}

.pembahasan-popup .modal-dialog img {
    max-height: 100% !important;
    max-width: 100% !important;
}
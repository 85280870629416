.pilih-topik-button {
    background-color: #FFFFFF !important;
    color: #0078D7 !important;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2), 0px 4px 8px rgba(0, 0, 0, 0.2) !important;
    border: 1px solid #0078D7 !important;    
}

.text-ellipsis {
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

/* Navbar */
.my-navbar {
  padding: 0;
}

.my-navbar .navbar-nav .nav-item .nav-link {
  padding: 24px 0;
  margin: 0 24px;
  border-bottom: 3px solid transparent;
  color: #000000A6;
}

.my-navbar .navbar-nav .nav-item .nav-link:hover {
  color: #000000CC;
}

.my-navbar .navbar-nav .nav-item .nav-link.active {
  color: #000000CC;
  border-bottom: 3px solid #ffbb34;
}

@media (max-width: 992px) {
  .my-navbar {
    padding: 8px;
  }
}

.my-navbar .nav-link {
  padding: 8px 24px;
}

.my-navbar .nav-link:hover {
  cursor: pointer;
  color: #ffbb34 !important;
}

.my-navbar .logo1,
.my-navbar .logoChild {
  position: relative;
  width: 36px;
  height: 36px;
}

.my-navbar .logoChild {
  border-radius: 12px;
  background-color: #e7e8ec;
  width: 1px;
  height: 34px;
}

.my-navbar .image21Icon {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}

.my-navbar .logo2 {
  position: relative;
  width: 114px;
  height: 33px;
}

.my-navbar .logo {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 18px;
}

.my-navbar .imageIcon {
  position: absolute;
  top: 0;
  left: 0;
  width: 36px;
  height: 36px;
  object-fit: cover;
}

.my-navbar .btn-toggle:hover {
  color: #ffbb34 !important;
}

.img-modal{
  position: relative;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
  padding: 3rem;
}
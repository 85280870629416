.rangkuman-things .button-white-primary {
    color: #0d6efd !important;
    background-color: white !important;
    border-color: #0d6efd !important;
}

.rangkuman-popup .form-label {
    color: #263238 !important;
    font-weight: 500 !important;
}

.rangkuman-popup .form-label .asterisk {
    color: red !important;
}

.rangkuman-popup .image-upload .close-button svg {
    color: red !important;
    position: absolute !important;
    top: 0 !important;
    right: 0 !important;
}

.rangkuman-popup .image-upload input {
    display: none;
}

.rangkuman-popup .image-upload img {
    height: 10rem !important;
    width: 9.25rem !important;
}

.rangkuman-popup .image-upload .image-row {
    max-width: 9.75rem !important;
    margin-right: 2.5rem !important;
    position: relative !important;
    padding-top: 8px !important;
    padding-right: 8px !important;
    vertical-align: top !important;
}

.rangkuman-popup .image-upload label {
    margin-top: 0 !important;
    padding-top: 8px !important;
}

.rangkuman-popup .modal-body {
    height: fit-content !important;
    padding-bottom: 0 !important;
}

.rangkuman-popup .modal-content {
    margin: auto !important;
    width: 62.5rem !important;
}

.rangkuman-popup .modal-content .image-name {
    text-align: center !important;
    overflow: hidden !important;
}

.rangkuman-things.popup-delete {
    max-width: 30.75rem !important;
    width: 30.75rem !important;
    padding: 1.84rem !important;
    border-radius: 0.5rem !important;
}

.rangkuman-things.popup-delete div.row:nth-child(2) {
    margin-top: 1.84rem !important;
}

.rangkuman-things.popup-delete div.row:nth-child(3) {
    margin-top: 2.75rem !important;
}

.rangkuman-things.popup-delete div.row:nth-child(4) {
    margin-top: 2.25rem !important;
}

.rangkuman-things.popup-delete div.row:nth-child(4) button {
    width: 9.0625rem !important;
}

.rangkuman-things.popup-delete .button-white-primary:hover {
    color: #0d6efd !important;
    background-color: white !important;
    border-color: #0d6efd !important;
}

.rangkuman-things.popup-delete .row > * {
    width:fit-content !important;
}

.rangkuman-popup.popup-detil .modal-content {
    height: 33.5rem !important;
}

.rangkuman-popup.popup-detil .modal-content .close-button {
    display: none !important;
}

.rangkuman-popup.popup-detil .modal-content span {
    font-weight: 600 !important;
}

.rangkuman-popup .modal-dialog {
    max-width: none !important;
}

.rangkuman-popup .modal-footer {
    border-top: 0 !important;
    padding-top: 0 !important;
}

.rangkuman-popup .modal-footer button {
    box-shadow: 0 .5rem 1rem rgba(1, 1, 1, .15);
    margin-left: 1.5rem !important;
    width: 9.0625rem !important;
}

.rangkuman-popup .modal-header {
    padding: 1.5rem !important;
}

.rangkuman-popup .modal-header .h4 {
    font-size: 1.125rem !important;
    font-weight: 500 !important;
}

.rangkuman-popup .navigation-col {
    max-width: 15.625% !important;
}

.rangkuman-popup .rangkuman-image-border {
    border-style: solid;
    border-width: thin;
}
.laporannilai-circle {
    width: 1.5rem;
    height: 1.5rem;
    display: inline-block;
    border-radius: 100%;
    margin-right: 1rem;
}

.laporannilai-circle.bg-green {
    background-color: #34a853;
}

.laporannilai-circle.bg-yellow {
    background-color: #fbbc04;
}

.laporannilai-circle.bg-red {
    background-color: #ea4335;
}

.laporannilai-circle.bg-gray {
    background-color: #979797;
}

.laporannilai-green {
    color: #34a853;
}

.laporannilai-yellow {
    color: #fbbc04;
}

.laporannilai-red {
    color: #ea4335;
}

.laporannilai-gray {
    color: #979797;
}

.laporan-nilai-pic{
    height: 2rem;
    width: 2rem;
    border-radius: 100%;
}
.laporan-nilai-pic > img{
    width: 100%; /* or any custom size */
    height: 100%; 
    object-fit: contain;
}
#video-container {
    margin-top: 2.25rem !important;
    width: 83.75% !important;
}

#video-container .list-group-item.active {
    z-index: 0 !important;
}

#video-container .col-deskripsi-catatan {
    min-height: 15rem !important;
    margin-bottom: 2rem !important;
    border: 1px solid #979797 !important;
}

#video-container .col-deskripsi-catatan ul.nav-tabs {
    background-color: lightgray;
    padding-top: 0.5rem !important;
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
}

#video-container .col-deskripsi-catatan li.nav-item {
    width: 50% !important;
}

#video-container .col-deskripsi-catatan li.nav-item button {
    width: 100% !important;
}

#video-container .video-timeline {
    width: 20rem !important;
}

#video-container .video-timeline .row {
    margin-right: 0 !important;
    margin-left: 0 !important;
}

#video-container .video-timeline .video-timeline-header {
    background-color: lightgray !important;
}

.rdw-embedded-modal, .rdw-link-modal, .rdw-image-modal {
    height: auto !important;
}

.rdw-dropdown-selectedtext {
    text-decoration: none !important;
    color: #000000 !important;
}

.public-DraftStyleDefault-block {
    margin: 1rem !important;
}

.public-DraftEditorPlaceholder-root {
    margin-left: 1rem !important;
}

.catatan-wrapper {
    border-bottom: 1px solid #979797 !important;
    min-height: 200px !important;
}

.catatan-toolbar {
    margin: 0 !important;
    background-color: #F0F0F0 !important;
}

.catatan-option-with-dropdown {
    background-color: rgba(255, 255, 255, 0.25) !important;
    border: 1px solid #757575;
    border-radius: 4px !important;
}

.catatan-option {
    background-color: transparent !important;
    padding: 1rem 0.75rem !important;
}

.rdw-option-active {
    background-color: rgba(255, 255, 255, 0.25) !important;
    border: 1px solid #757575;
    border-radius: 4px !important;
}

.text-ellipsis-y {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.goalsImage {
  width: 100%;
  height: 446px;
  object-fit: contain;
}

.desktopInner,
.frameGroup {
  display: flex;
  align-items: center;
  justify-content: center;
}

.desktopInner {
  align-self: stretch;
  background-color: #fff;
  flex-direction: column;
  padding: 80px 10px;
  color: #10326f;
}
.paragraph2 {
  position: relative;
  font-size: 16px;
  line-height: 24px;
  color: #525a80;
  display: inline-block;
  width: 613.67px;
}
.headingGroup {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
}
.paragraph3,
.paragraph4 {
  align-self: stretch;
  position: relative;
}
.paragraph3 {
  line-height: 31px;
  font-weight: 600;
}
.paragraph4 {
  font-size: 14px;
  line-height: 24px;
  color: #525a80;
}
.paragraphParent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
}
.onlineCoursesTeach {
  font-size: 32px;
}
.onlineCoursesTeach,
.paragraph1 {
  position: relative;
  line-height: 44px;
}
.paragraph1 {
  align-self: stretch;
  font-size: 16px;
  line-height: 24px;
  color: #525a80;
  text-align: left;
}
.onlineCoursesTeachTheStudeParent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
}
.div {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 24px;
}
.number1 {
  border-radius: 100px;
  background-color: #fff0f0;
  flex-direction: column;
  padding: 11px 0;
}
.div1 {
  position: relative;
  line-height: 124%;
  font-weight: 600;
  display: inline-block;
  width: 52px;
  text-align: center;
}
.number {
  border-radius: 100px;
  background-color:  #ebebff;
  display: flex;
  flex-direction: column;
  padding: 11px 0;
  align-items: center;
  justify-content: center;
}
.div2,
.number1,
.number2 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.div2 {
  align-self: stretch;
  flex-direction: row;
  gap: 24px;
  color: #ff6e41;
}
.number2 {
  border-radius: 100px;
  background-color: #e1f7e3;
  flex-direction: column;
  padding: 11px 0;
}
.div4,
.frameContainer,
.parent {
  display: flex;
  gap: 24px;
}
.parent {
  align-self: stretch;
  font-size: 24px;
  color: #5557cd;
  font-family: 'Inter';
}
.div4 {
  align-self: stretch;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: #23bd33;
}
.onlineCoursesTeachTheStudeParent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
}
.menciptakanPeluangPekerjaan {
  flex: 1;
  position: relative;
  font-size: 16px;
  line-height: 24px;
  font-family: 28px;
  color: #525a80;
  text-align: left;
}
.frameContainer,
.parent {
  display: flex;
  gap: 24px;
}
.timeline {
    margin: var(--margin);
    height: var(--load-height);
    width: var(--load-width);
    background-color: var(--default-timeline);
    position: relative;
}

.timeline-progress {
    width: var(--progress-bar-width);
    height: var(--progress-bar-height);
    background-color: var(--done-timeline);
}

.timeline-items {
    position: var(--items-position);
    top: var(--items-top);
    margin: var(--margin-items);
    height: var(--items-height);
    display: flex;
    justify-content: var(--justify-content-items);
    flex-direction: var(--flex-direction);
}

.timeline-item {
    position: relative;
}

.timeline-item:focus {
    outline: none !important;
    box-shadow: none !important;
}

.timeline-item::before {
    content: var(--content);
    width: 1.875rem;
    height: 1.875rem;
    background-color: var(--tertiary-timeline);
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    border-radius: 100%;
    color: var(--quaternary-timeline);
    font-weight: bold;
}

.timeline-item:disabled {
    opacity: inherit !important;
}

.timeline-content-top {
    position: absolute;
    bottom: 2.5rem;
    left: 50%;
    transform: var(--transform-content);
    width: 20rem;
    border-radius: 5px;
    text-align: center;
    color: var(--secondary-timeline);
}

.timeline-content-bottom {
    position: absolute;
    top: 2.5rem;
    left: 50%;
    transform: var(--transform-content);
    width: 20rem;
    border-radius: 5px;
    text-align: center;
    color: var(--secondary-timeline);
}

.timeline-item.active::before {
    background-color: var(--primary-timeline);
    color: var(--quaternary-timeline);
}

.timeline-item.active.last::before {
    background-color: var(--quaternary-timeline);
    color: var(--primary-timeline);
    border: 0.5px solid var(--primary-timeline);
}

.timeline-item.active .timeline-content {
    color: var(--primary-timeline);
}

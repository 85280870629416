.img-content {
  width: 70px;
  height: 70px;
  border-radius: 100%;
  object-fit: cover;
}

.card-benefit {
  border-radius: 24px;
  padding: 24px 16px 24px 24px;
}

.card-title-benefit {
  font-weight: 600;
  margin: auto 0;
  line-height: 31px;
  font-size: 19px;
}

.card-content-benefit {
  font-size: 19px;
  color: #525a80;
}
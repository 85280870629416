#home-talent .below-header-talent {
    padding-top: 0 !important;
}
/* menimpa bootstrap */
.progress{
    border-radius: 20px !important;
}
.progress.progress-basic .progress-bar{
    background-color: #5d5fef !important;
}
.progress.progress-full .progress-bar{
    background-color: #34a853 !important;
}
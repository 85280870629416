.back-bab-button:focus {
    outline: none !important;
    box-shadow: none !important;
}

.list-bab-kuis-button {
    color: #34A853 !important;
    font-size: 0.875rem !important;
    font-weight: 700 !important;
    padding: 0.5rem !important;
    background: #FFFFFF !important;
    box-shadow: 0px 2px 4px rgba(0, 120, 215, 0.2), 0px 4px 8px rgba(0, 120, 215, 0.2) !important;
    border-radius: 4px !important;
    width: 9rem !important;
}

.accordion-timeline-header {
    padding: 1.25rem !important;
    padding-bottom: 0 !important;
    background-color: transparent !important;
}

.accordion-timeline-header > button:not(.collapsed) {
    border-bottom: 1px solid var(--border-color-acordion) !important;
}

.accordion-timeline-header > button {
    box-shadow: none !important;
    background-color: transparent !important;
    padding: 0 !important;
    padding-bottom: 1rem !important;
}

.accordion-timeline-header > button:not(.collapsed)::after {
	background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23000000%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e") !important;
}

.accordion-timeline-header.last-seen > button:not(.collapsed)::after {
	background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23ffffff%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e") !important;
}

.accordion-timeline-header.last-seen > button::after {
	background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23ffffff%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e") !important;
}
.search-bab > input {
    border-top-left-radius: .5rem;
    border-bottom-left-radius: .5rem;
}
.search-bab > span {
    border-top-right-radius: .5rem;
    border-bottom-right-radius: .5rem;
}
.search-bab-br-05 {
    border-radius: .5rem !important;
}

#bab-download-popup .checkbox {
    height: 30px !important;
    width: 30px !important;
}
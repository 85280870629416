.back-login-button {
    color: #003399 !important;
    font-weight: 500 !important;
}

.back-login-button:focus {
    outline: none !important;
    box-shadow: none !important;
}

.wrap {
    text-align: center;
    position: relative;
}

.wrap::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    border-top: 1px solid black;
    background: black;
    width: 100%;
    transform: translateY(-50%);
}

.links {
    padding: 0 10px;
    display: flex;
    justify-content: center;
    position: relative;
}

.login-form-header {
    margin-bottom: 2rem !important;
}

.login-button {
    margin-top: 2.25rem !important;
    margin-bottom: 2.25rem !important;
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
    font-size: 1rem !important;
}

.login-button-google-logo {
    font-size: 1.75rem !important;
    margin-right: 1rem !important;
}

.login-footer {
    margin-bottom: 2.25rem !important;
}

.register-link {
    margin-bottom: 2.25rem !important;
}

.login-logo {
    margin: 0 0 3rem 0 !important;
}

.login-banner-col {
    padding: 13rem 5rem 5rem 5rem !important;
}

.login-form-col {
    padding: 6rem 9rem 5rem 9rem !important;
}

.regis-form-col {
    padding: 3rem 6rem 5rem 6rem !important;
}

@media screen and (max-width: 1600px) {
    .login-form-header {
        margin-top: 2rem !important;
        margin-bottom: 1rem !important;
    }
    
    .login-button {
        margin-top: 1rem !important;
        margin-bottom: 1rem !important;
        padding-top: 0.25rem !important;
        padding-bottom: 0.25rem !important;
        font-size: 1rem !important;
    }
    
    .login-button-google-logo {
        font-size: 1.25rem !important;
        margin-right: 0.5rem !important;
    }

    .login-footer {
        margin-bottom: 1rem !important;
    }

    .register-link {
        margin-bottom: 1rem !important;
    }

    .login-logo {
        margin: 5rem 0 2rem 0 !important;
    }

    .login-banner-col {
        padding: 1rem 1rem 1rem 1rem !important;
    }
    
    .login-form-col {
        padding: 1rem 1rem 1rem 1rem !important;
    }
}

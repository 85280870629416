.ellipseParent {
  position: relative;
  background-color: #10326f;
  overflow: hidden;
  flex-shrink: 0;
  text-align: left;
  font-size: 46px;
  color: #fff;
}

@media (max-width: 991px) {
  .heading span {
    font-size: 28px;
  }
}

.containerHero {
  margin: 80px 0;
}

@media (max-width: 991px) {
  .containerHero {
    margin: 0px 0;
  }
}

.instanceChild,
.instanceItem {
  position: absolute;
  top: -348px;
  left: -181px;
  border-radius: 50%;
  background-color: #154394;
  width: 1338px;
  height: 1338px;
  max-width: 100%;
}
.instanceItem {
  left: -301px;
  background-color: #2854a3;
}
.instanceInner {
  position: absolute;
  top: 143px;
  left: 214px;
  background-color: #154394;
  width: 440px;
  height: 53px;
}
.workOpportunities {
  color: #ffbb34;
  background-color: #154394;
  padding: 0 12px;
  white-space: nowrap;
}
.asset21 {
  z-index: 5;
  height: 394px;
}
.heading,
.paragraph {
  align-self: stretch;
  position: relative;
  line-height: 56px;
}

.paragraph {
  font-size: 16px;
  line-height: 24px;
}


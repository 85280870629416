body {
    overflow-x: hidden;
    /* Hide horizontal scrollbar */
}

#main-wrapper {
    background-color: rgba(240, 240, 240, 1);
    height: 100vh;
}

#content {
    background-color: rgba(240, 240, 240, 1);
    padding: 38px 115px 0px 115px;
}

#soal-praktik {
    background-color: white;
    padding: 65px;
}

#jawaban {
    background-color: white;
    margin: 24px 0px 0px 0px;
    padding: 24px 24px 0px 24px;
}

.title {
    font-size: 24px;
    font-weight: 700;
}

.button-outline-danger {
    color: #ff0000 !important;
    font-size: 0.875rem !important;
    font-weight: 700 !important;
    padding: 0.5rem !important;
    background: #FFFFFF !important;
    box-shadow: 0px 2px 4px rgba(0, 120, 215, 0.2), 0px 4px 8px rgba(0, 120, 215, 0.2) !important;
    border-radius: 4px !important;
    width: 9rem !important;
}

.button-outline-danger:hover {
    color: #ffffff !important;
    font-size: 0.875rem !important;
    font-weight: 700 !important;
    padding: 0.5rem !important;
    background: #ff0000 !important;
    box-shadow: 0px 2px 4px rgba(0, 120, 215, 0.2), 0px 4px 8px rgba(0, 120, 215, 0.2) !important;
    border-radius: 4px !important;
    width: 9rem !important;
}

.button-success {
    color: #ffffff !important;
    font-size: 0.875rem !important;
    font-weight: 700 !important;
    padding: 0.5rem !important;
    background: rgba(52, 168, 83, 1) !important;
    box-shadow: 0px 2px 4px rgba(0, 120, 215, 0.2), 0px 4px 8px rgba(0, 120, 215, 0.2) !important;
    border-radius: 4px !important;
    width: 9rem !important;
}
.button-success:hover {
    color: #ffffff !important;
    font-size: 0.875rem !important;
    font-weight: 700 !important;
    padding: 0.5rem !important;
    background: rgb(40, 128, 63) !important;
    box-shadow: 0px 2px 4px rgba(0, 120, 215, 0.2), 0px 4px 8px rgba(0, 120, 215, 0.2) !important;
    border-radius: 4px !important;
    width: 9rem !important;
}
.soal-title{
    font-size: 24px;
    font-weight: 700;
}
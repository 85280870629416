.text-ellipsis {
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.materi-nav-link:not(.active) {
    background-color: #F0F0F0 !important;
    color: #000000 !important;
}

.materi-nav-link.active {
    color: #0078D7 !important;
    text-decoration: underline !important;
}

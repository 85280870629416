#leaderboard-container .leaderboards-picker .dropdown-toggle::after {
    display: none !important;
}

#leaderboard-container .leaderboards-picker .dropdown button {
    background-color: white !important;
    border: 2px !important;
    border-radius: 5px !important;
    width: 16rem !important;
}

#leaderboard-container #ranking-tab-tabpane-all-time .dropdown button {
    background-color: transparent !important;
}

#leaderboard-container #ranking-tab-tabpane-all-time .leaderboards-disabledpicker {
    background-color: #F0F0F0 !important;
}

#leaderboard-container .nav-item {
    width: calc(100%/3) !important;
}

#leaderboard-container .nav-tabs {
    border: 0 !important;
}

#leaderboard-container .nav-item button {
    border: 0;
    color: black !important;
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
    width: 100% !important;
}

#leaderboard-container .nav-link.active {
    border-bottom: 3px solid #0078d7;
}

.rank-number-1 {
    background-color: #FFD700;
}

.rank-number-2 {
    background-color: #C0C0C0;
}

.rank-number-3 {
    background-color: #CD7F32;
}

#leaderboard-container .tab-content .list-group-item .rank-number {
    text-align: center;
    width: 25px;
}

#leaderboard-container .react-datepicker__input-container input {
    border: 0 !important;
    font-weight: 400 !important;
    color: black !important;
    height: 2.3rem !important;
    width: inherit !important;
}

#leaderboard-container .react-datepicker__input-container input:focus {
    display: inline-block;
    border: 0 !important;
    color: black !important;
    height: 2.3rem !important;
    outline: none !important;
}

#leaderboard-container .react-datepicker-wrapper {
    width: 11rem !important;
}

#leaderboard-container .react-datepicker-wrapper .react-datepicker__input-container {
    width: inherit !important;
}

#leaderboard-container .leaderboards-monthpicker svg {
    height: 1.55rem !important;
}

#leaderboard-container .leaderboards-monthpicker {
    width: 15rem !important;
}

#leaderboard-container .leaderboards-monthpicker .react-datepicker-wrapper {
    width: inherit !important;
}

#leaderboard-container .leaderboards-weekpicker .dropdown {
    width: 14.5rem !important;
}

#leaderboard-container .leaderboards-weekpicker .dropdown button {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

#leaderboard-container .leaderboards-weekpicker .dropdown .btn.btn-primary:hover {
    color: black !important;
}

#leaderboard-container .tab-content .list-group-item.user-row {
    background-color: #0078d7 !important;
    color: white !important;
}

#leaderboard-container .tab-content .list-group-item.user-row .rank-number {
    color: black !important;
}

#popup-leaderboard .profil-modal-body {
    padding: 1rem 5rem !important;
}
.footer {
  color: white;
  background: #10326f;
  padding: 80px 0 40px;
}

.box-container {
  gap: 0 12px;
}
.box-socmed {
  padding: 14px;
  background: #2854a3;
}

.box-socmed:hover {
  padding: 14px;
  background: #132951;
}

.box-socmed img {
  width: 18px;
  height: 18px;
}

.logo-aaa {
  width: 36px;
  height: 36px;
}

.logo-tujuhsembilan {
  width: 114px;
  height: 33px;
}

.slogan-space {
  margin: 20px 0;
}

.footer-child-title {
  font-size: 19px;
  font-weight: 600;
  margin-bottom: 20px;
}

.footer-link {
  padding: 0;
  list-style: none;
}

.footer-link li {
  line-height: 24px;
  margin-bottom: 8px;
}

.footer-link li a {
  font-size: 16px;
  line-height: 24px;
  color: white;
  text-decoration: none;
}

.footer-link li a:hover {
  cursor: pointer;
  color: #ffbb34 !important;
}

.logo1,
.logoChild {
  position: relative;
  width: 36px;
  height: 36px;
}

.logoChild {
  border-radius: 12px;
  background-color: #e7e8ec;
  width: 1px;
  height: 34px;
}

.image21Icon {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}

.logo2 {
  position: relative;
  width: 114px;
  height: 33px;
}

.logo {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 18px;
}

.imageIcon {
  position: absolute;
  top: 0;
  left: 0;
  width: 36px;
  height: 36px;
  object-fit: cover;
}